<template>
  <div>
    <vs-popup class="sm:popup-w-60" title="Tambah Cara Bayar" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <label class="ml-1 text-xs">COA *</label>
            <div @click="isModalCoaActive = true">
              <vx-input-group>
                <vs-input placeholder="Pilih COA" :value="coaText" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Group 1 *</label>
            <v-select :options="['SALES', 'BUY', 'PURCHASE', 'PROCUREMENT', 'AR', 'AP', 'SPP', 'PO', 'BOOKING FEE', 'KONTRAK', 'REIMBURSE']" :clearable="false" v-model="data.group1"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Group 2</label>
            <v-select :options="['CREDIT', 'DEBIT', 'AR', 'CASH', 'PETTY CASH', 'CASH ADVANCE']" :clearable="false" v-model="data.group2"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Kode *</label>
            <vs-input class="w-full" placeholder="Generate Otomatis" v-model="data.kode" disabled/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Nama *</label>
            <vs-input class="w-full" v-model="data.nama"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modals-->
      <vs-popup class="sm:popup-w-50 popup-content-no-padding"
                title="Pilih Data COA"
                :active="isModalCoaActive"
                v-on:update:active="isModalCoaActive = $event">
        <Coa :selectable="true" :externalFilter="filterCoa" @selected="onSelectedModalCoa"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import CaraBayarRepository from '@/repositories/master/cara-bayar-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import Coa from '@/views/pages/master/coa/Coa'
import vSelect from 'vue-select'

export default {
  name: 'CaraBayarAdd',
  props: ['isActive'],
  components: {
    Coa,
    ValidationErrors,
    'v-select': vSelect
  },
  data () {
    return {
      isModalCoaActive: false,
      isLoading: false,
      errors: null,
      data: {
        coa: {}
      }
    }
  },
  watch: {
    'data.coa' (value) {
      this.data.id_coa = value.id
    },
    'data.proyek' (value) {
      this.data.id_proyek = value.id
    }
  },
  computed: {
    filterCoa () {
      return {
        tipe: 'DETAIL'
      }
    },
    coaText () {
      if (this.data.coa.id == null) return ''
      return `${this.data.coa.kode} - ${this.data.coa.nama}`
    }
  },
  methods: {
    onSelectedModalCoa (item) {
      this.data.coa = item
      this.isModalCoaActive = false
    },

    save () {
      this.errors = null
      this.isLoading = true

      CaraBayarRepository.create(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
